/* eslint-disable @typescript-eslint/no-explicit-any */
import MixpanelConfig from "@/constants/mixpanel/config";
import withGlobalComponents from "@/global-components/hocs";
import "@/styles/bottomSheetOverlide.css";
import colors from "@/styles/colors";
import "@/styles/globals.css";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import mixpanel from "mixpanel-browser";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { useRouter } from "next/router";
import { useEffect, useLayoutEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import { versions } from "../versions";

const theme = { colors };
declare global {
  interface Window {
    Kakao: any;
  }
}

mixpanel.init(MixpanelConfig.projectToken(), {
  debug: MixpanelConfig.debug(),
});

mixpanel.register({
  merakiWebVersion: versions[0].version,
});

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

growthbook.init();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [isMyDoctorApp, setIsMyDoctorApp] = useState<boolean>(true);
  const [userId, setUserId] = useState<number>(NaN);

  useEffect(() => {
    growthbook.setURL(window.location.href);
    growthbook.setAttributes({
      id: mixpanel.get_distinct_id(),
      browser: navigator.userAgent,
      url: router.pathname,
    });
  }, [router.pathname]);

  // google tag manager
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-TBND3HD" });
  }, []);

  useLayoutEffect(() => {
    const isMobile = navigator.userAgent?.indexOf("Mobi") !== -1 ? true : false;
    const isMyDoctorApp =
      navigator.userAgent?.indexOf("MyDoctorApp") !== -1 ? true : false;
    const userId = Number(
      navigator.userAgent?.split("userId=")[1]?.split(" ")[0]
    );

    if (isMyDoctorApp === false) {
      setIsMobile(isMobile);
      setIsMyDoctorApp(isMyDoctorApp);
    }

    setUserId(userId);
  }, []);

  //@ts-ignore
  pageProps.isMobile = isMobile;
  //@ts-ignore
  pageProps.isMyDoctorApp = isMyDoctorApp;
  //@ts-ignore
  pageProps.userId = userId;

  // branch sdk
  useEffect(() => {
    async function initAndFetch() {
      const BranchSDK = (await import("branch-sdk")).default;

      const BRANCH_KEY = "key_live_ii8JmO0NSSGgOZ18z00AkogjFAn8EyNw";

      BranchSDK.init(BRANCH_KEY, {});
    }

    initAndFetch();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      {withGlobalComponents(() => (
        <>
          <ToastContainer
            toastStyle={{
              alignSelf: "center",
              padding: "12px 20px",
              textAlign: "center",
              fontSize: "14px",
              height: "45px",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              borderRadius: "8px",
              whiteSpace: "pre-wrap",
              top: 90,
            }}
            icon={false}
            position="top-center"
            autoClose={1500}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={false}
            theme="dark"
          />
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />
          </ThemeProvider>
        </>
      ))}
    </GrowthBookProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default MyApp;
