import { createPortal } from "@/global-components/utils";
import { useEffect, useState, ReactNode } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";

type BottomSheetProps = {
  children: ReactNode;
};

const BottomSheet = (props: BottomSheetProps) => {
  const { children } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setTimeout(() => setIsOpen(false), 1)}
      detent="content-height"
      style={{ maxWidth: "560px", margin: "0 auto" }}
    >
      <Sheet.Container>
        <Sheet.Header>
          <Header />
        </Sheet.Header>

        <Sheet.Content>
          <Sheet.Scroller>{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => setTimeout(() => setIsOpen(false), 1)} />
    </Sheet>
  );
};

export default createPortal(BottomSheet);

const Header = styled.div`
  height: 20px;
  position: relative;
  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 36px;
    height: 4px;
    top: calc(8px + env(safe-area-inset-top));
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    background-color: var(--rsbs-handle-bg, hsla(0, 0%, 0%, 0.14));
  }
`;
