import { Color, ColorKey } from "../Color";
import Assets from "./assets";

export type SvgIconName = keyof typeof Assets;

export type SvgIconSize = keyof typeof SIZE_STYLES;

type SvgIconProps = {
  size: SvgIconSize;
  iconName: SvgIconName;
  color?: ColorKey;
};

const SvgIcon = (props: SvgIconProps) => {
  const { size, iconName, color = "black" } = props;

  const { width, height } = SIZE_STYLES[size];

  const Asset = Assets[iconName];

  return (
    <div
      style={{
        width,
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Asset height={height} width={width} color={Color[color]} />
    </div>
  );
};

const SIZE_STYLES = {
  4: {
    width: 4,
    height: 4,
  },
  12: {
    width: 12,
    height: 12,
  },
  16: {
    width: 16,
    height: 16,
  },
  20: {
    width: 20,
    height: 20,
  },
  24: {
    width: 24,
    height: 24,
  },
  32: {
    width: 32,
    height: 32,
  },
  36: {
    width: 36,
    height: 36,
  },
  40: {
    width: 40,
    height: 40,
  },
  48: {
    width: 48,
    height: 48,
  },
  96: {
    width: 96,
    height: 96,
  },
  location: {
    width: 12,
    height: 15,
  },
} as const;

export default SvgIcon;
