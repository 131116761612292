const MixpanelConfig = {
  projectToken: () => {
    if (process.env.NEXT_PUBLIC_SERVER === "PRODUCTION") {
      return "2719ffa92aa7b1b992738b18511a948d"; // 나만의닥터 App과 별개의 프로젝트
      // return "547baff43888754aa642d913af3f9d8f"; // 나만의닥터 App과 같은 프로젝트
    } else {
      return "183c714e848b21f6d4ee7b19d5355ad6"; // 나만의닥터 App과 별개의 프로젝트
      // return "72120a40f446e34c909701f19d0d73fb"; // 나만의닥터 App과 같은 프로젝트
    }
  },
  debug: () => {
    if (process.env.NEXT_PUBLIC_SERVER === "PRODUCTION") {
      return false;
    } else {
      return true;
    }
  },
};

export default MixpanelConfig;
